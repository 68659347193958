export enum StarPrinterModel {
    Unknown = 'Unknown',
    TSP650II = 'TSP650II',
    TSP700II = 'TSP700II',
    TSP800II = 'TSP800II',
    TSP100IIIW = 'TSP100IIIW',
    TSP100IIILAN = 'TSP100IIILAN',
    TSP100IIIBI = 'TSP100IIIBI',
    TSP100IIIU = 'TSP100IIIU',
    TSP100IV = 'TSP100IV',
    TSP100IV_SK = 'TSP100IV_SK',
    mPOP = 'mPOP',
    mC_Print2 = 'mC_Print2',
    mC_Print3 = 'mC_Print3',
    mC_Label3 = 'mC_Label3',
    SM_S210i = 'SM_S210i',
    SM_S230i = 'SM_S230i',
    SM_T300 = 'SM_T300',
    SM_T300i = 'SM_T300i',
    SM_T400i = 'SM_T400i',
    SM_L200 = 'SM_L200',
    SM_L300 = 'SM_L300',
    BSC10 = 'BSC10',
    TSP043 = 'TSP043',
    SP700 = 'SP700',
    TUP500 = 'TUP500',
    SK1_2xx = 'SK1_2xx',
    SK1_3xx = 'SK1_3xx'
}